export const structureAttrs = [
    'geojson',
    'type',
    'values',
    'labels',
    'parents',
    'value',
    'delta.reference',
    'gauge.shape',
    'gauge.threshold.value',
    'radius',
    'x',
    'y',
    'z',
    'measure',
    'open',
    'high',
    'low',
    'close',
    'a',
    'b',
    'c',
    'u',
    'v',
    'w',
    'i',
    'j',
    'k',
    'branchvalues',
    'featureidkey',
    'thetaunit',
    'node.label',
    'node.groups',
    'node.x',
    'node.y',
    'link.source',
    'link.target',
    'link.value',
    'link.label',
    'orientation',
    'starts.x',
    'starts.y',
    'starts.z',
    'header.values',
    'cells.values',
    'header.fill.color',
    'header.font.color',
    'header.font.size',
    'cells.fill.color',
    'cells.font.color',
    'cells.font.size',
    'columnwidth',
    'columnorder',
    'intensity',
    'facecolor',
    'vertexcolor',
    'transpose',
];

export const structureSpecialAttrs = {
    sunburst: ['ids'],
    treemap: ['ids'],
    indicator: ['ids', 'mode'],
    scatterpolar: ['r', 'theta'],
    scatterpolargl: ['r', 'theta'],
    barpolar: ['r', 'theta'],
};

export const styleSpecialAttrs = {
    sunburst: [
        'level',
        'maxdepth',
        'leaf.opacity',
        'sunburstcolorway',
        'extendsunburstcolors',
    ],
    treemap: [
        'level',
        'maxdepth',
        'leaf.opacity',
        'treemapcolorway',
        'extendtreemapcolors',
    ],
    pie: ['piecolorway'],
    funnelarea: [
        'funnelareacolorway',
        'extendfunnelareacolors',
        'aspectratio',
        'baseratio',
        'funnelmode',
        'funnelgap',
        'funnelgroupgap',
    ],
    bar: ['barmode', 'barnorm', 'bargap', 'bargroupgap'],
    histogram: ['barmode', 'barnorm', 'bargap', 'bargroupgap'],
    waterfall: ['waterfallmode', 'waterfallgap', 'waterfallgroupgap'],
    box: ['boxmode', 'boxgap', 'boxgroupgap'],
    violin: ['violinmode', 'violingap', 'violingroupgap'],
    scatter: ['stackgroup', 'stackgaps', 'orientation', 'groupnorm'],
};

export const styleAttrs = [
    'name',
    'opacity',
    'showlegend',
    'legendgroup',
    'sizeref',
    'sizemode',
    'anchor',
    'maxdisplayed',
    'color',
    'title.text',
    'titleposition',
    'title.font.family',
    'title.font.size',
    'histfunc',
    'histnorm',
    'cumulative.enabled',
    'cumulative.direction',
    'cumulative.currentbin',
    'header.height',
    'header.fill.color',
    'header.font.family',
    'header.font.size',
    'header.align',
    'header.font.color',
    'header.line.color',
    'cells.height',
    'cells.fill.color',
    'cells.font.family',
    'cells.font.size',
    'cells.align',
    'cells.line.color',
    'mode',
    'flatshading',
    'xbins.start',
    'xbins.end',
    'nbinsx',
    'xbins.size',
    'ybins.start',
    'ybins.end',
    'nbinsy',
    'ybins.size',
    'base',
    'offset',
    'width',
    'whiskerwidth',
    'sort',
    'direction',
    'rotation',
    'hole',
    'pull',
    'boxpoints',
    'points',
    'jitter',
    'pointpos',
    'marker.color',
    'marker.sizeref',
    'marker.sizemode',
    'marker.sizemin',
    'marker.symbol',
    'marker.line.width',
    'marker.line.color',
    'marker.maxdisplayed',
    'connector.visible',
    'connector.fillcolor',
    'connector.line.color',
    'connector.line.dash',
    'connector.mode',
    'increasing.name',
    'increasing.line.width',
    'increasing.line.color',
    'increasing.marker.color',
    'increasing.marker.line.color',
    'increasing.marker.line.width',
    'increasing.fillcolor',
    'increasing.line.dash',
    'increasing.showlegend',
    'decreasing.name',
    'decreasing.line.width',
    'decreasing.line.color',
    'decreasing.marker.color',
    'decreasing.marker.line.color',
    'decreasing.marker.line.width',
    'decreasing.fillcolor',
    'decreasing.line.dash',
    'decreasing.showlegend',
    'totals.marker.color',
    'totals.marker.line.color',
    'totals.marker.line.width',
    'tickwidth',
    'contours.type',
    'contours.coloring',
    'contours.showlines',
    'contours.showlabels',
    'autocontour',
    'ncontours',
    'contours.size',
    'contours.start',
    'contours.end',
    'line.width',
    'line.color',
    'line.showscale',
    'line.colorscale',
    'line.dash',
    'line.shape',
    'line.smoothing',
    'connectgaps',
    'fill',
    'fillcolor',
    'notched',
    'notchwidth',
    'textposition',
    'texttemplate',
    'textinfo',
    'text',
    'textfont.family',
    'textfont.size',
    'textfont.color',
    'insidetextorientation',
    'textangle',
    'constraintext',
    'cliponaxis',
    'colorscale',
    'showscale',
    'reversescale',
    'zauto',
    'zmin',
    'zmax',
    'cauto',
    'cmin',
    'cmax',
    'zsmooth',
    'xgap',
    'ygap',
    'lighting.ambient',
    'lighting.diffuse',
    'lighting.specular',
    'lighting.roughness',
    'lighting.fresnel',
    'lighting.vertexnormalsepsilon',
    'lighting.facenormalsepsilon',
    'lightposition.x',
    'lightposition.y',
    'lightposition.z',
    'scalegroup',
    'scalemode',
    'spanmode',
    'bandwidth',
    'span',
    'side',
    'boxmean',
    'box.visibile',
    'box.width',
    'box.color',
    'box.line.width',
    'box.line.color',
    'meanline.visible',
    'meanline.width',
    'meanline.color',
    'node.color',
    'node.pad',
    'node.thickness',
    'node.line.color',
    'node.line.width',
    'arrangement',
    'link.color',
    'link.line.color',
    'link.line.width',
    'pathbar.visible',
    'pathbar.side',
    'hoveron',
    'hoverongaps',
    'hovertemplate',
    'hoverinfo',
    'hoverlabel.split',
    'hoverlabel.namelength',
    'contour.show',
    'contour.color',
    'contour.width',
    'hoverlabel.align',
    'valueformat',
    'valuesuffix',
    'error_x',
    'error_y',
    'error_z',
];
